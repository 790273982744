<template>
  <div v-editable="blok">
    <div class="container">
      <div class="mb-9 grid gap-9 lg:grid-cols-3">
        <PropertyCard v-for="card in cards" :key="card.uuid" v-bind="card" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  BlockPropertyListCuratedStoryblok,
  ContentTypePropertyStoryblok,
} from '@/types/storyblok'
import type { FetchAPIOptions } from '@/composables/useStoryblokHelpers'

const { fetchAsyncData } = useStoryblokHelpers()

const props = defineProps<{
  blok: BlockPropertyListCuratedStoryblok
}>()

const defaultPerPage = 2
const perPage = computed(() =>
  props.blok.quantity ? parseInt(props.blok.quantity) : defaultPerPage,
)

const uuids = computed(() => props.blok.properties?.join(',') || '')
const community = computed(() =>
  isContentTypeCommunityStoryblok(props.blok.community)
    ? props.blok.community
    : undefined,
)
const type = computed(() => props.blok.type)

const apiOptions = computed<FetchAPIOptions>(() => ({
  // Filter properties by community they're in
  ...(type.value === 'community' && {
    starts_with: `${community.value?.full_slug}`,
    per_page: perPage.value,
  }),
  content_type: 'ContentTypeProperty',
  sort_by: 'first_published_at:desc',
  page: 1,
  excluding_fields: 'content,photoGallery,videoGallery',
  // Filter properties by uuid
  // Filtering by Community uid takes precedence over manually selected properties
  ...(type.value === 'manual' && {
    by_uuids_ordered: uuids.value,
  }),
  resolve_relations: [
    'ContentTypeProperty.erpProperty,ContentTypeProperty.propertyConfig,ContentTypePropertyConfig.builder',
  ],
}))

const { fetchStatusEntries } = usePropertyStatus()
const [{ data, clear, refresh }] = await Promise.all([
  fetchAsyncData<ContentTypePropertyStoryblok>('stories', apiOptions, {
    getCachedData(key, nuxtApp) {
      return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
    },
  }),
  fetchStatusEntries(),
])

// Necessary await to resolve the property type
await usePropertyTypeEntriesStore().fetchPropertyTypeEntries()
const cards = computed(
  () =>
    data.value?.data.stories.map((property) => {
      const { propertyCardProps } = usePropertyData(property.content)

      return {
        ...propertyCardProps.value,
        uuid: property.uuid,
        link: { cached_url: property.full_slug },
      }
    }) || [],
)

watch(
  [uuids, perPage, community, type],
  () => {
    clear()
    refresh()
  },
  { deep: true },
)
</script>
